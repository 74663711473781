<template>
  <div class="ticket">
    <div class="search-area">
      <div class="search-item">
        <label>选择店铺</label>
        <el-cascader :options="shopOptions" v-model="shopIds" :props="shopOptionProps" ref="cascader" :filterable="true"
          size="medium" clearable>
        </el-cascader>
      </div>
      <div class="search-item">
        <label>统计方式</label>
        <el-select v-model="timeType" size="medium" @change="changeTimeType">
          <el-option v-for="item in timeTypeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-item" v-show="timeType === 'day'">
        <label>起止日期</label>
        <el-date-picker v-model="days" type="daterange" size="medium" range-separator="-" value-format="yyyy-MM-dd"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="search-item" v-show="timeType === 'month'">
        <label>请选择月</label>
        <el-date-picker v-model="months" type="month" size="medium" placeholder="选择月">
        </el-date-picker>
      </div>
      <div class="search-item">
        <label>统计类型</label>
        <el-checkbox-group v-model="meterType">
          <el-checkbox label="electric">电表</el-checkbox>
          <el-checkbox label="coldWater">冷水表</el-checkbox>
          <el-checkbox label="hotWater">热水表</el-checkbox>
        </el-checkbox-group>
      </div>
      <div>
        <el-button type="primary" size="medium" @click="searchEvent">查询</el-button>
        <el-button size="medium" @click="resetEvent">重置</el-button>
        <el-button type="primary" size="medium" @click="exportEvent">导出</el-button>
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" border size="medium" :span-method="objectSpanMethod"
        style="width: 100%; margin-top: 20px" class="el-table">
        <el-table-column prop="shopName" label="店铺名称"></el-table-column>
        <el-table-column prop="shopNo" label="店铺号"></el-table-column>
        <el-table-column prop="floorName" label="楼层"></el-table-column>
        <el-table-column prop="roomName" label="水电表名称"></el-table-column>
        <el-table-column prop="ammeterCost" label="电表结算用量(kWh)" width="150"
          v-if="meterType.includes('electric')"></el-table-column>
        <el-table-column prop="ammeterConsume" label="电表费用" v-if="meterType.includes('electric')"></el-table-column>
        <el-table-column prop="coldwaterCost" label="冷水表结算用量m³" width="150"
          v-if="meterType.includes('coldWater')"></el-table-column>
        <el-table-column prop="coldwaterConsume" label="冷水表费用" v-if="meterType.includes('coldWater')"></el-table-column>
        <el-table-column prop="hotwaterCost" label="热水表结算用量m³" width="150"
          v-if="meterType.includes('hotWater')"></el-table-column>
        <el-table-column prop="hotwaterConsume" label="热水表费用" v-if="meterType.includes('hotWater')"></el-table-column>
        <el-table-column prop="totalConsume" label="费用合计"></el-table-column>
        <el-table-column prop="balance" label="账户余额"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { onDownload, filterDayDate, disabledDayDate } from "@/utils/utils";
export default {
  data () {
    return {
      childList: [],
      shopIds: [],
      shopOptions: [],
      shopOptionProps: {
        value: 'id',
        label: 'name',
        children: 'childList'
      },
      timeTypeOptions: [
        {
          value: 'day',
          label: '按日统计'
        }, {
          value: 'month',
          label: '按月统计'
        }
      ],
      timeType: 'month',
      days: [],
      months: moment().subtract(1, 'months'),
      meterType: ['electric', 'coldWater', 'hotWater'],
      tableData: []
    };
  },
  created () {
    this.getShopList()
    this.getItemList()
  },
  methods: {
    changeTimeType () {
      this.days = []
      this.months = ''
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      let spanLength = row.items.length
      if (columnIndex < 3) {
        if (row.merge) {
          return {
            rowspan: spanLength,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    // 导出
    exportEvent () {
      let params = this.getParams()
      if (!params.starttime || !params.endtime) {
        let message = this.timeType === 'day' ? '请选择起止日期' : '请选择月份'
        this.$message.error(message)
        return
      }
      if (this.timeType === 'day') {
        if (moment(this.days[1]).diff(this.days[0], 'days', true) > 30) {
          this.$message.error('查询范围不能超过31天')
          return
        }
      }
      if (!params.showMeter) {
        this.$message.error('请选择统计类型')
        return
      }
      this.$api.merchant.exportBillApi(params).then(({ headers, data }) => {
        onDownload(headers, data)
      })
    },
    searchEvent () {
      this.getItemList()
    },
    resetEvent () {
      this.timeType = 'month'
      this.shopIds = []
      this.days = []
      this.months = moment().subtract(1, 'months')
      this.meterType = ['electric', 'coldWater', 'hotWater']
      this.getItemList()
    },
    findElementById (data = [], targetId) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item.id === targetId) {
          return item;
        }
        if (item.childList && item.childList.length > 0) {
          const result = this.findElementById(item.childList, targetId);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    getParams () {
      let currentShop = this.findElementById(this.childList, this.shopIds[this.shopIds.length - 1])
      let params = {
        tenantName: currentShop ? currentShop.shopCode : null,
        showMeter: this.meterType.join(),
        showPub: true
      }
      if (this.timeType === 'day') {
        if (this.days && this.days.length) {
          params.starttime = this.days[0]
          params.endtime = this.days[1]
        }
      }
      if (this.timeType === 'month') {
        if (this.months) {
          params.starttime = moment(this.months).startOf('month').format('YYYY-MM-DD')
          params.endtime = moment(this.months).endOf('month').format('YYYY-MM-DD')
        }
      }
      return params
    },
    getItemList () {
      let params = this.getParams()
      if (!params.starttime || !params.endtime) {
        let message = this.timeType === 'day' ? '请选择起止日期' : '请选择月份'
        this.$message.error(message)
        return
      }
      if (this.timeType === 'day') {
        if (moment(this.days[1]).diff(this.days[0], 'days', true) > 30) {
          this.$message.error('查询范围不能超过31天')
          return
        }
      }
      if (!params.showMeter) {
        this.$message.error('请选择统计类型')
        return
      }
      this.$api.merchant.getRechargeBillListApi(params).then(({ code, data }) => {
        if (code !== 200) return
        this.tableData = this.generateTableData(data)
      })
    },
    generateTableData (data) {
      let tableData = []
      data.forEach(shop => {
        if (Array.isArray(shop.items)) {
          shop.items.forEach((item, index) => {
            let room = {
              ...item,
              shopName: shop.shopName,
              shopNo: shop.shopNo,
              floorName: shop.floorName,
              items: shop.items,
              merge: index === 0
            }
            tableData.push(room)
          })
        }
      })
      return tableData
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.busapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.shopOptions = child
          }
        })
    }
  },
};
</script>

<style scoped lang='scss'>
.ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  height: calc(100vh - 155px);
}

.search-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.search-item {
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }

  .el-table {
    flex: 1;
    overflow-y: auto;
    margin-top: 20px;
  }
}

.d-paging {
  text-align: center;
}
</style>